@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

:root {

  --hex-dark-blue: #285dab;
  --rgb-dark-blue: 40 93 171;
  --dark-blue: rgb(var(--rgb-dark-blue));

  --hex-pale-blue: #3d78ce;
  --rgb-pale-blue: 61, 120, 206;
  --pale-blue: rgb(var(--rgb-pale-blue));

  --hex-yellow: #edbb34;
  --rgb-yellow: 237 187 52;
  --yellow: rgb(var(--rgb-yellow));

  --hex-pink: #ed87a1;
  --rgb-pink: 237 135 161;
  --pink: rgb(var(--rgb-pink));

  /* From me */
  --almost-black: #333333;
  --grey: #666666;

  --hex-brown: #83580b;
  --rgb-brown: 133, 90, 10;
  --brown: rgb(var(--rgb-brown));

  /* Pantone 377 C - from my chair */
  --hex-green: #7a9a01;
  --rgb-green: 119, 152, 1;
  --green: rgb(var(--rgb-green));

  /* From fontawesome website design */
  --rgb-gray-50: 240 241 243;
  --rgb-gray-100: 224 226 232;
  --rgb-gray-200: 195 198 209;
  --rgb-gray-300: 165 171 187;
  --rgb-gray-400: 165 171 187;
  --rgb-gray-500: 97 109 138;
  --rgb-gray-600: 81 94 123;
  --rgb-gray-700: 54 71 103;
  --rgb-gray-800: 24 49 83;
  --rgb-gray-900: 0 28 64;
  --white: var(--oc-white);
  --black: var(--oc-black);
  --gray-50: rgb(var(--rgb-gray-50));
  --gray-100: rgb(var(--rgb-gray-100));
  --gray-200: rgb(var(--rgb-gray-200));
  --gray-300: rgb(var(--rgb-gray-300));
  --gray-400: rgb(var(--rgb-gray-400));
  --gray-500: rgb(var(--rgb-gray-500));
  --gray-600: rgb(var(--rgb-gray-600));
  --gray-700: rgb(var(--rgb-gray-700));
  --gray-800: rgb(var(--rgb-gray-800));
  --gray-900: rgb(var(--rgb-gray-900));
}

#root {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-top: 0;

  display: grid;
  grid-gap: 0rem;
  grid-template-columns: 1fr;
  grid-template-areas: 'nav' 'header' 'main' 'footer';
  grid-auto-rows: minmax(1rem, auto);

  word-break: break-word;
}

/* GRID DEFINTIONS

Ref https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Grids

*/


header {
  grid-area: header
}

footer {
  grid-area: footer;
}

nav {
  grid-area: nav;
  background-color: var(--hex-pale-blue);
  color: white;
  display: grid;
  padding: 0.5rem 0rem 0.5rem 0rem;
  margin: 0rem;
  grid-gap: 0rem;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'nav-branding nav-menu';
  align-items: center;
  justify-items: center;
}

#nav-branding {
  grid-area: nav-branding;
  margin-left: 0.5rem;
}

#nav-menu {
  grid-area: nav-menu;
}

nav a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin-right: 1.5rem
}

nav a:hover {
  color: white;
  text-decoration: underline;
  text-decoration-thickness: .5em;
}

main {
  grid-area: main;
  text-align: left;
}

article {
  display: grid;

  &.info {
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: '. section-info .';

    @media (max-width: 40rem) {
      grid-template-columns: auto;
    }
  }

  &#map-widget {
    grid-gap: 0rem;
    grid-template-columns: 1fr;
    grid-template-areas: 'section-google-map' 'section-map-info';
  }

  &#farm,
  &#alpaca {
    grid-gap: 0rem;
    grid-template-columns: 1fr;
    grid-template-areas: 'section-overview' 'section-more';
  }
}

.info section {
  grid-area: section-info;

  & div {
    margin-bottom: 3rem;
  }
}

section.overview {
  grid-area: section-overview;
  padding: 0 0.5rem 0 0.5rem;

  /* Auto align extra content such as if there is a photo on the alpaca page */
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: max-content;
}

section.more {
  grid-area: section-more;
  padding: 0 0.5rem 0 0.5rem;
}

#search-page {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-areas: '. search-form .' '. search-results .';
}

#search-page .search-form {
  grid-area: search-form;
}

#search-page .search-results {
  grid-area: search-results;
}


@media (min-width: 60rem) {
  #root article#map-widget {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'section-google-map section-map-info'
  }

  article#farm,
  article#alpaca {
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas:
      'section-more section-overview'
  }

}

@media (max-width: 59rem) {

  #search-address>.button-text,
  #search-alpacas-farms>.button-text {
    display: none;
  }
}

@media (max-width: 40rem) {
  .hidden-mobile {
    display: none;
  }
}

section#google-map {
  grid-area: section-google-map;
  width: 100%;
  height: 100vh;
  margin-bottom: 1rem;
}

section#map-info {
  background-color: white;
  grid-area: section-map-info;
  padding: 0 0.5rem 0 0.5rem;
  text-align: left;
}

/* REUSABLE BOX STYLING */

.box {
  /* Inspired by https://fminus.org/state/new-york/?_sector=wind-energy */
  background-color: rgb(var(--rgb-gray-50) / 0.5);
  border: 1px solid var(--gray-100);
  border-left: 0.2rem solid rgb(var(--rgb-pink) / 0.5);
  box-shadow: 0 0.1rem 0.2rem var(--gray-100);
  margin-bottom: 1rem;
  padding: 0 0.5rem 1rem 0.5rem;

  &.profile-graphic {
    text-align: center;

    img.graphic {
      margin-top: 1rem;
      width: 80%;
    }
  }

  .inner-box {
    background-color: white;
    padding: 0.75rem;

    .box-row {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 2rem auto;
      grid-template-areas: 'icon text';
    }

    .icon {
      grid-area: icon;
      text-align: center;
    }

    .content {
      display: grid;
      grid-gap: 0rem;
      grid-template-columns: 1fr 3fr;
      grid-template-areas: 'heading text';

      @media (max-width: 40rem) {
        grid-template-columns: auto;
        grid-template-areas: 'heading' 'text';
      }

      .heading {
        grid-area: heading;
        margin-block-start: 0em;
        margin-block-end: 0.5em;
      }

      .text {
        grid-area: text;
        text-align: left;
        margin-block-end: 1em;
      }


    }
  }
}



/* SEARCH */

#map-search {
  padding: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  grid-gap: 0rem;
  align-items: center;
  justify-content: center;
}

#map-search-modifier {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-grow: 1;
}

#form-search-by-address {
  display: flex;
  flex-grow: 1;
}

#map-search-by-address {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
}

#form-search-by-alpaca {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;

  @media (max-width: 40rem) {
    flex-direction: column;
  }
}

#text-search-by-alpaca {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 0rem;
}

/*
Google autocomplete styling
Ref: https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
*/

.pac-container {
  padding: 0.5rem;

  .pac-item>span {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    color: var(--almost-black);
  }
}

.pac-icon>.pac-icon-marker {
  vertical-align: middle;
  margin-bottom: 0.2rem;
}

.pac-item-selected {
  background: rgb(218 112 214 / 0.25)
}

.pac-item:hover {
  background: rgb(51 51 51 / 0.25)
}

/* TEXT */

body {
  text-align: center;
  font-size: 1rem;
  color: var(--almost-black);
  background-color: white;
  margin: 0;
  padding: 0;

  @media (max-width: 40rem) {
    font-size: 1.5rem;
  }
}

a {
  color: var(--dark-blue);
}

a:hover {
  color: var(--pale-blue);
  text-decoration: underline;
  text-decoration-thickness: .5em;
}

.search-results em {
  font-weight: bold;
  font-style: normal;
  background-color: yellow;
}

.strikethrough {
  text-decoration: line-through;
}

aside {
  border-radius: 1rem;
  box-shadow: 0 0 0 0.5rem var(--hex-yellow);
  border: 0.125rem solid var(--almost-black);
  margin: 1rem 0 1rem 0;
  padding: 1rem;
  margin-bottom: 3rem;
}

/* BUTTONS */

button {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0.125rem solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0 0.125rem var(--gray-200);
  background-color: var(--pink);
}

button:hover {
  border: 0.125rem solid;
  border-color: var(--gray-500);
  box-shadow: 0 0.25rem 0 0.125rem var(--gray-400);
}

button:active {
  transform: scale(0.98);
  box-shadow: none;
}

button:disabled {
  transform: none;
  box-shadow: none;
  opacity: 0.5;

  &:hover {
    border: 0.125rem solid transparent;
  }
}

.pill {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  color: #fff;
}

.full {
  border-radius: 3rem;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.half {
  border-radius: 3rem 0rem 0rem 3rem;
  margin: 0.5rem 0rem 0.5rem 0.5rem;
}

.half-flipped {
  border-radius: 0rem 3rem 3rem 0rem;
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}

input#autocomplete,
input#search-by-alpaca {
  border: 2px solid rgb(128 128 128 / 0.65);
  background-color: white;
  width: 100%;
  color: var(--almost-black);
}

/* FACETS */

button.facet {
  color: var(--grey);
  background-color: white;
  border: 0.125rem solid var(--gray-200);
}

button.facet:hover {
  border-color: var(--gray-500);

  .icon {
    color: var(--pink);
  }
}

/* PHOTOS */

img.photo {
  width: 80%;
  height: 100%;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.5rem var(--pink);
  border: 0.125rem solid var(--almost-black);
  margin: 0.5rem 0 0.5rem 0;
}

/* LOGO */

#nav-branding a img.logo {
  vertical-align: bottom;
  width: 4rem;
  height: 4rem;

  /* 
  Override default inline so image does not align to baseline of nav text
  This removes some pixels below the image and thus the logo looks vertically centered

  Ref: https://gaurav5430.medium.com/extra-4px-at-the-bottom-of-html-img-8807a7ab0ca2
  */
}

img#large-logo {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

/* ICONS */

.icon {
  padding: 0 0.2em 0 0;
}

.button-text::before {
  /* Adds a small gap between the text and icon without having to set eg margin-left: 0.25rem */
  content: " ";
}

.icon {
  &.link-arrow {
    margin-left: 0.5rem;
  }
}

#address-selected-farm img.icon {
  width: 1em;
  height: 1em;
}

/* MAP */

#map {
  /* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 Ref: https://developers.google.com/maps/documentation/javascript/overview#maps_map_simple-css
 */

  height: 100vh;
  width: 100%;
}

/* MARKERS */

.farm-marker {
  padding: 0.75rem;
  background-color: white;
  color: var(--gray-700);
  transition: all 0.3s ease-out;
  width: auto;
  max-width: 15rem;
  border-radius: 1rem;
  border-width: .2rem;
  border-style: solid;

  &.public {
    border-color: var(--green);
  }

  &.private {
    border-color: var(--brown);
  }

  .summary {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.5rem;
  }

  .count {
    font-weight: bolder;
  }

  .more-info {
    display: flex;
    border-radius: 0.5rem;
    flex: 1;
    flex-direction: column;

    &.hidden {
      display: none
    }
  }

  .city {
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    border: 1px solid var(--gray-100);
    max-width: fit-content;
  }

  .address {
    font-size: 1rem;
    font-style: normal;
    margin-top: 1rem;
  }

  .farm-marker-link {
    font-size: 1rem;
    font-style: normal;
    margin: 1rem 0 0.5rem 0;
    border-radius: 1rem;
    padding: 1rem 0.5rem;
    border: 0.125rem solid var(--gray-500);
    box-shadow: 0 0.25rem 0 0.125rem var(--gray-200);

    .text {
      margin: 0 0 0 1rem;
    }
  }

  .farm-marker-link:hover {
    border-color: var(--pink);
    box-shadow: 0 0.25rem 0 0.125rem var(--gray-400);
  }

  &.public .icon {
    color: var(--green)
  }

  &.private .icon {
    color: var(--brown)
  }
}


.farm-marker::after {
  /* Pointer under farm marker - taken from css in https://developers.google.com/maps/documentation/javascript/advanced-markers/html-markers*/
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%) translateY(calc(0.1em * -1));
  /* translateY inspired from hotels.com */
  width: 0;
}

.farm-marker.public::after {
  border-top: 8px solid var(--green);
}

.farm-marker.private::after {
  border-top: 8px solid var(--brown);
}

.highlight {
  /*background: var(--dark-blue);*/
  /* TODO Add some styling to make it stand out */
  color: var(--almost-black)
}
